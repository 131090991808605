import { getFbUserPromise } from "@/lib/foundation/firebase";
import { isRole } from "@/lib/meat/roles";
import { getSchoolFromEmail, getSchools } from "@/lib/meat/schools";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/schools/")({
  beforeLoad: async ({ context }) => {
    const [user, schools, isPilot] = await Promise.all([
      getFbUserPromise(),
      getSchools(context.queryClient),
      isRole("pilot"),
    ]);

    const school = getSchoolFromEmail(user, schools);

    // if (!isPilot && school != null) {
    //   throw redirect({
    //     to: "/dashboard/add-course",
    //     params: { schoolId: school.id },
    //   });
    // }
  },
});

import { useFbUser } from "@/lib/foundation/firebase";
import {
  addCourseToDashboard,
  useDashboardContentNode,
} from "@/lib/meat/dashboard/utils";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/_auth/schools/$schoolId/all/$courseId")({
  component: () => <Wrapper />,
});

function Wrapper() {
  const user = useFbUser()!;
  const dashboardContentNode = useDashboardContentNode(user.uid);

  const { schoolId, courseId } = Route.useParams();

  useEffect(() => {
    addCourseToDashboard(dashboardContentNode, schoolId, courseId).catch(
      console.error
    );
  }, []);

  return <Outlet />;
}

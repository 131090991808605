import { useParams } from "@tanstack/react-router";
import { useState } from "react";
import { ampl } from "@/lib/foundation/amplitude";
import { useFbUser } from "@/lib/foundation/firebase";
import { cn } from "@/lib/foundation/misc";
import { IconButton } from "@/lib/interface/button";
import { useDialogShown, useShowDialog } from "@/lib/interface/dialog";
import { LeaderboardSheet } from "../leaderboard-modal";
import { ReferralSheet } from "../referral-modal";
import { useSchoolFromEmail } from "../schools";

import Discord from "@/assets/discord.svg?react";
import leaderboard from "@/assets/leaderboard.png";
import { ContactUsDialog } from "./contact";

export function AppBarActionsDivider({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "flex h-full justify-center items-stretch py-[18px] px-[20px]",
        className
      )}
    >
      <div className="w-[1px] bg-border"></div>
    </div>
  );
}

export function AppBarSpacer() {
  return <div className="grow min-w-4" />;
}

export function DeprecatedAppBarActions({ className }: { className?: string }) {
  const user = useFbUser();
  const showDialog = useShowDialog();
  const [showingReferralSheet, setShowingReferralSheet] = useState(false);
  const [showingLeaderboardSheet, setShowingLeaderboardSheet] = useState(false);
  const dialogShown = useDialogShown();
  const schoolIdFromEmail = useSchoolFromEmail(user);

  return (
    <div className={cn("flex flex-row gap-4 shrink-0", className)}>
      {showingReferralSheet && (
        <ReferralSheet
          onClose={() => dialogShown || setShowingReferralSheet(false)}
        />
      )}
      {showingLeaderboardSheet && (
        <LeaderboardSheet
          onClose={() => dialogShown || setShowingLeaderboardSheet(false)}
        />
      )}

      {schoolIdFromEmail != null && (
        <IconButton
          className="text-[32px] leading-[32px] w-[32px] h-[32px]"
          onClick={() => {
            ampl.track("Referral Button Clicked");
            setShowingReferralSheet(true);
          }}
        >
          💸
        </IconButton>
      )}

      {schoolIdFromEmail != null && (
        <IconButton
          onClick={() => {
            ampl.track("Discord Button Clicked");
            window.open("https://discord.gg/y5fF2ktSz4", "_blank");
          }}
        >
          <Discord className="w-8 h-8 shrink-0" />
        </IconButton>
      )}

      <IconButton
        className="hidden md:flex"
        onClick={() => {
          ampl.track("Leaderboard Button Clicked");
          setShowingLeaderboardSheet(true);
        }}
      >
        <img alt="leaderboard" src={leaderboard} className="w-8 h-8" />
      </IconButton>
      <IconButton
        className="hidden md:flex text-[32px] leading-[32px] w-[32px] h-[32px]"
        onClick={() =>
          showDialog((onClose) => <ContactUsDialog onClose={onClose} />)
        }
      >
        ✉️
      </IconButton>
    </div>
  );
}

import { Combobox } from "@headlessui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { cn } from "../foundation/misc";
import Fuse from "fuse.js";

export function PCombobox<T>({
  className,
  values,
  value,
  getKey,
  getLabel,
  onChange,
  onCreate,
  disabled = false,
  fuse,
  getOptionLabel,
}: {
  className?: string;
  values: T[];
  value: T | null;
  getKey: (value: T | null) => string;
  getLabel: (value: T | null) => string;
  getOptionLabel?: (value: T) => ReactNode;
  onChange: (value: T | null) => void;
  onCreate?: ((value: string) => void) | null;
  disabled?: boolean;
  fuse?: Fuse<T>;
}) {
  const [query, setQuery] = useState("");

  const filteredValues = useMemo(() => {
    return fuse
      ? fuse.search(query, { limit: 100 }).map((result) => result.item)
      : values.filter((value) =>
          getLabel(value).toLowerCase().includes(query.toLowerCase()),
        );
  }, [fuse, query, values]);

  useEffect(() => {
    setQuery(getLabel(value));
  }, [value, getLabel]);

  return (
    <Combobox
      disabled={disabled}
      value={value}
      by={(a, b) => getKey(a) === getKey(b)}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <div className={cn("relative flex flex-col", className)}>
        <Combobox.Input
          className={
            "px-[19px] py-[10px] leading-[22px] placeholder:font-medium border-border border-solid border bg-transparent flex-1 outline-primary rounded-lg"
          }
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
            onChange(null as T);
          }}
          displayValue={(value) => getLabel(value as T)}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
        />
        <Combobox.Options
          className={
            "absolute z-10  outline-primary bg-white dropdown-shadow max-h-96 min-w-[159px] rounded-md text-base overflow-y-auto dumb-scrollbar top-full mt-[17px] left-0"
          }
        >
          {query.length > 0 && onCreate && (
            <Combobox.Option
              key="create"
              value={null}
              className={({ active }) =>
                cn(
                  "text-nowrap select-none relative py-3 px-5 text-left cursor-pointer",
                  active && "bg-primary bg-opacity-5",
                  value === null
                    ? "text-opacity-40 text-foreground"
                    : "text-opacity-100 text-foreground",
                )
              }
              onClick={() => {
                onCreate(query);
              }}
            >
              Create &quot;{query}&quot;
            </Combobox.Option>
          )}
          {filteredValues.map((value) => (
            <Combobox.Option
              key={getKey(value)}
              value={value}
              className={({ active }) =>
                cn(
                  "text-nowrap select-none relative py-3 px-5 text-left cursor-pointer",
                  active && "bg-primary bg-opacity-5",
                  value === null
                    ? "text-opacity-40 text-foreground"
                    : "text-opacity-100 text-foreground",
                )
              }
            >
              {getOptionLabel ? getOptionLabel(value) : getLabel(value)}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

import { PNode } from "@/lib/foundation/p-node/p-node";
import {
  FbPNode,
  useFbPNode,
  useFbValue,
} from "../../foundation/p-node/firebase";
import { fbDbRef } from "@/lib/foundation/firebase";
import { get } from "firebase/database";

export const dashboardSortOptions = ["Recent", "Alphabetical"] as const;

type DashboardSortOption = (typeof dashboardSortOptions)[number];

export const dashboardCourseColors = [
  "#6DACBF",
  "#DA7979",
  "#DCD069",
  "#7EC6BE",
  "#B6D789",
  "#E8A08E",
  "#EFB67D",
  "#E485B6",
];

export type DashboardContent = {
  school?: string;
  courses?: string[];
  courseColors?: Record<string, string>;
  sortOption?: DashboardSortOption;
};

export function useDashboardContentNode(uid: string) {
  return useFbPNode<DashboardContent | undefined>(`/dashboard_content/${uid}`);
}

export function useDashboardContent(uid: string) {
  return useFbValue<DashboardContent | undefined>(`/dashboard_content/${uid}`);
}

export function useDashboardSchool(uid: string) {
  return useFbValue<string | undefined>(`/dashboard_content/${uid}/school`);
}

export function useDashboardSortOption(uid: string) {
  const [sortOption, setSortOption] = useFbValue<
    DashboardSortOption | undefined
  >(`/dashboard_content/${uid}/sortOption`);

  return [sortOption ?? "Recent", setSortOption] as const;
}

export async function addCourseToDashboard(
  dashboardContentNode: PNode<DashboardContent | undefined>,
  schoolId: string,
  courseId: string
) {
  const existingCourses = dashboardContentNode.value?.courses ?? [];

  if (existingCourses.includes(courseId)) {
    return;
  }

  const courseNameRef = fbDbRef(`/courses/${courseId}/name`);

  const courseName = await get(courseNameRef);

  if (!courseName.exists()) {
    return;
  }

  const existingColors =
    dashboardContentNode.value?.courses
      ?.map((id) => dashboardContentNode.value?.courseColors?.[id])
      .filter((color) => color != null) ?? [];
  let availableColors = dashboardCourseColors.filter(
    (color) => !existingColors.includes(color)
  );
  if (availableColors.length === 0) {
    availableColors = dashboardCourseColors;
  }
  return await dashboardContentNode.transact((currentData) => {
    if (currentData == null) {
      return {
        school: schoolId,
        courses: [courseId],
        courseColors: { [courseId]: availableColors[0] },
      };
    } else {
      return {
        school: currentData.school,
        courses: Array.from(
          new Set([...(currentData.courses ?? []), courseId])
        ),
        courseColors: {
          ...currentData.courseColors,
          [courseId]: availableColors[0],
        },
      };
    }
  });
}

import {
  AppBar,
  AppBarCustomContentProvider,
} from "@/lib/meat/app-bar/app-bar";
import { Outlet, createRootRoute, redirect } from "@tanstack/react-router";
import { Suspense, lazy } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ShowDialogWrapper } from "@/lib/interface/dialog";
import { redirectToPlatform } from "@/lib/meat/platform";
import { ShowContextMenuContextProvider } from "@/lib/interface/context-menu/components";
import { IntercomProvider } from "@/lib/interface/intercom";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const queryClient = new QueryClient();

export const Route = createRootRoute({
  beforeLoad: ({ search }) => {
    redirectToPlatform();

    return {
      queryClient,
      customAppBar: false,
    };
  },
  component: () => {
    return (
      <main className="flex flex-col bg-background relative select-none">
        <AppBarCustomContentProvider>
          <QueryClientProvider client={queryClient}>
            <IntercomProvider>
              <ShowContextMenuContextProvider>
                <ShowDialogWrapper>
                  <AppBar className="shrink-0 z-10" />
                  <Outlet />
                  <ReactQueryDevtools />
                  <Suspense>
                    <TanStackRouterDevtools />
                  </Suspense>
                </ShowDialogWrapper>
              </ShowContextMenuContextProvider>
            </IntercomProvider>
          </QueryClientProvider>
        </AppBarCustomContentProvider>
      </main>
    );
  },
});

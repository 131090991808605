export interface Listenable {
  subscribe(listener: () => void): () => void;
}

export class ListenableNotifier implements Listenable {
  private listeners: Set<() => void> = new Set();
  private deactivate: (() => void) | null | undefined = null;

  constructor(private readonly activator?: () => () => void) {}

  subscribe(listener: () => void): () => void {
    if (this.listeners.size === 0) {
      this.deactivate = this.activator?.();
    }

    this.listeners.add(listener);

    return () => {
      this.listeners.delete(listener);

      if (this.listeners.size === 0) {
        this.deactivate?.();
        this.deactivate = null;
      }
    };
  }

  notify(): void {
    this.listeners.forEach((listener) => listener());
  }
}

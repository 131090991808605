import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/campus-ambassador/")({
  validateSearch: (params) => {
    return {
      source: params.source,
    } as {
      source?: string;
    };
  },
});

import { useBreakpoint } from "@/lib/foundation/breakpoint";
import { Vec2 } from "@/lib/foundation/math";
import { cn } from "@/lib/foundation/misc";
import {
  CSSProperties,
  ReactNode,
  createContext,
  useEffect,
  useState,
} from "react";

export type ShowContextMenuCallback = (
  render: (onClose: () => void) => React.ReactNode,
) => void;

export const ShowContextMenuContext = createContext<ShowContextMenuCallback>(
  () => {},
);

export function ShowContextMenuContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [contextMenu, setContextMenu] = useState<React.ReactNode>();
  return (
    <ShowContextMenuContext.Provider
      value={(render) => {
        setContextMenu(render(() => setContextMenu(undefined)));
      }}
    >
      {children}
      {contextMenu && (
        <ContextMenuLayer onClose={() => setContextMenu(undefined)}>
          {contextMenu}
        </ContextMenuLayer>
      )}
    </ShowContextMenuContext.Provider>
  );
}

type ContextMenuLayerProps = {
  children: React.ReactNode;
  onClose: () => void;
};

function ContextMenuLayer({ children, onClose }: ContextMenuLayerProps) {
  useEffect(() => {
    const initialScrollX = window.scrollX;
    const initialScrollY = window.scrollY;
    window.onscroll = () => scrollTo(initialScrollX, initialScrollY);
    return () => {
      window.onscroll = () => {};
    };
  }, []);
  return (
    <div
      className="fixed top-0 left-0 w-dvw h-dvh z-30 overflow-hidden fit-content"
      onClick={onClose}
      onContextMenu={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      {children}
    </div>
  );
}

export type ContextMenuProps = {
  className?: string;
  position?: Vec2;
  children: ReactNode;
};

export function ContextMenu({
  className,
  position,
  children,
}: ContextMenuProps) {
  const mobile = !useBreakpoint("md");
  const clientWidth = window.innerWidth;
  const clientHeight = window.innerHeight;
  const defaultStyle: CSSProperties = {};
  if (position !== undefined) {
    if (mobile) {
      if (position.x < clientWidth / 2) {
        defaultStyle.left = position.x + 32;
      } else {
        defaultStyle.right = clientWidth - position.x + 32;
      }
      if (position.y < clientHeight / 4) {
        defaultStyle.top = position.y + 16;
      } else {
        defaultStyle.bottom = clientHeight - position.y + 16;
      }
    } else {
      defaultStyle.left = position.x;
      defaultStyle.top = position.y;
    }
  }
  const [style, setStyle] = useState<CSSProperties>(defaultStyle);
  // TODO: dynamically move the context menu if it collides with the edge of the viewport
  return (
    <div
      className={cn(
        "absolute bg-white rounded-xl flex flex-col select-none w-fit shadow-lg border z-10 overflow-hidden py-2",
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}

type ContextMenuChildProps = {
  className?: string;
  icon?: ({ className }: { className: string }) => ReactNode;
  iconClassName?: string;
  children: ReactNode;
};

export function ContextMenuChild({
  className,
  icon,
  iconClassName,
  children,
}: ContextMenuChildProps) {
  const defaultIconClassName = "w-5 h-5 inline mr-[6px]";
  return (
    <div
      className={cn(
        "flex flex-row items-center text-nowrap cursor-pointer pl-3 pr-4 py-2 hover:bg-foreground/5 w-full",
        className,
      )}
    >
      {icon ? (
        icon({ className: cn(defaultIconClassName, iconClassName) })
      ) : (
        <div className={defaultIconClassName} />
      )}
      {children}
    </div>
  );
}

import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "./button";
import { cn, useAsyncFn } from "../foundation/misc";
import { DelayedCenteredSpinner } from "./spinner";
import { ReactNode } from "@tanstack/react-router";
import * as Sentry from "@sentry/react";
import { UserFriendlyError } from "../meat/error-screen";
const ShowDialogContext = createContext<
  <T>(
    render: (onClose: (result?: T) => void) => React.ReactNode,
    className?: string,
  ) => Promise<T | undefined>
>(() => Promise.resolve(undefined));

const DialogShownContext = createContext<boolean>(false);

export function ShowDialogWrapper({ children }: { children: React.ReactNode }) {
  const [dialog, setDialog] = useState<React.ReactNode>(null);
  const dialogResolveRef = useRef<((result: any) => void) | null>(null);
  const [className, setClassName] = useState<string>("");

  const showDialog = useCallback(
    <T,>(
      render: (onClose: (result?: T) => void) => React.ReactNode,
      className?: string,
    ) => {
      return new Promise<T | undefined>((resolve) => {
        dialogResolveRef.current = resolve;
        setTimeout(() => {
          setDialog(
            render((result) => {
              setDialog(null);
              setClassName("");
              dialogResolveRef.current?.(result);
              dialogResolveRef.current = null;
            }),
          );
          setClassName(className ?? "");
        }, 1);
      });
    },
    [],
  );

  return (
    <DialogShownContext.Provider value={dialog != null}>
      <ShowDialogContext.Provider value={showDialog}>
        <Dialog
          open={dialog != null}
          onClose={() => {
            setDialog(null);
            setClassName("");
            dialogResolveRef.current?.(undefined);
            dialogResolveRef.current = null;
          }}
          className={cn(
            "z-50 fixed inset-0 flex flex-col items-center justify-center top-0 left-0 w-full h-full bg-foreground/50 px-4 overflow-y-auto py-24",
            className,
          )}
        >
          {dialog}
        </Dialog>
        {children}
      </ShowDialogContext.Provider>
    </DialogShownContext.Provider>
  );
}

export function useShowDialog() {
  return useContext(ShowDialogContext);
}

export function useDialogShown() {
  return useContext(DialogShownContext);
}

export function AlertDialog({
  title,
  message,
  onClose,
  className,
}: {
  title: string;
  message: ReactNode;
  onClose: () => void;
  className?: string;
}) {
  return (
    <DialogPanel className={className}>
      <Dialog.Title
        className={"font-heading text-[24px] font-medium text-center"}
      >
        {title}
      </Dialog.Title>
      <Dialog.Description className={"opacity-70 mt-[6px] text-center"}>
        {message}
      </Dialog.Description>

      <Button className="mt-[30px]" outline onClick={onClose}>
        Close
      </Button>
    </DialogPanel>
  );
}

export function ConfirmActionDialog({
  title,
  message,
  action,
  onClose,
  onConfirm,
  className,
}: {
  title: string;
  message: ReactNode;
  action: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  className?: string;
}) {
  const tryPopup = useTryPopup();

  const [confirm, confirming] = useAsyncFn(() =>
    tryPopup(async () => {
      await onConfirm();
      onClose();
    }),
  );

  return (
    <DialogPanel className={cn("max-w-xl", className)}>
      <Dialog.Title
        className={"font-heading text-[24px] font-medium text-center"}
      >
        {title}
      </Dialog.Title>
      <Dialog.Description className={"opacity-70 mt-[6px] text-center"}>
        {message}
      </Dialog.Description>

      <div className="flex flex-row gap-4">
        <Button className="mt-[30px] basis-0 grow" outline onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={confirming}
          loading={confirming}
          className="mt-[30px] basis-0 grow"
          onClick={confirm}
        >
          {action}
        </Button>
      </div>
    </DialogPanel>
  );
}

export function DialogPanel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Dialog.Panel
      className={cn(
        "bg-white rounded-[17px] p-[30px] flex flex-col items-stretch min-w-[300px]",
        className,
      )}
    >
      <Suspense fallback={<DelayedCenteredSpinner />}>{children}</Suspense>
    </Dialog.Panel>
  );
}

export function useTryPopup() {
  const showDialog = useShowDialog();

  return useCallback(
    async (fn: () => Promise<void>) => {
      try {
        await fn();
      } catch (error: any) {
        let message = error?.message;
        if (error?.code === "ERR_NETWORK") {
          message =
            "Network error: please check your internet connection. If the issue persists, please contact support.";
        } else if (!(error instanceof UserFriendlyError)) {
          Sentry.captureException(error);
        }
        showDialog((onClose) => (
          <AlertDialog title="Notice" message={message} onClose={onClose} />
        ));
      }
    },
    [showDialog],
  );
}

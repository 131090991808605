export function handleCodeInput(
  e: React.KeyboardEvent<HTMLTextAreaElement>,
  onChange: () => void,
) {
  const textArea = e.target as HTMLTextAreaElement;

  if (e.key === "Tab") {
    e.preventDefault();

    if (e.shiftKey) {
      doUnindent(textArea);
    } else {
      if (
        getSelectionSize(textArea) === 0 &&
        getIfAnyCharactersToLeftOfSelection(textArea)
      ) {
        doStandardTab(textArea);
      } else {
        doIndent(textArea);
      }
    }

    onChange();
  } else if (e.key === "Backspace") {
    if (
      getSelectionSize(textArea) === 0 &&
      !getIfAnyCharactersToLeftOfSelection(textArea) &&
      !selectionIsBeginningOfLine(textArea)
    ) {
      e.preventDefault();
      doUnindent(textArea);
      onChange();
    }
  } else if (e.key === "Enter") {
    e.preventDefault();

    const leftChar = textArea.value[textArea.selectionStart - 1];

    if (["{", "[", ":", "("].includes(leftChar)) {
      doEnter(textArea, 1);
    } else {
      doEnter(textArea, 0);
    }
  }
}

function doEnter(textArea: HTMLTextAreaElement, additionalIndent?: number) {
  const { startLine } = getSelectionLineNumbers(textArea);

  const lines = textArea.value.split("\n");
  const firstCharX = lines[startLine].search(/\S|$/);

  document.execCommand("insertText", false, "\n");

  const spacesToAdd = firstCharX + (additionalIndent ?? 0) * 4;

  if (spacesToAdd > 0) {
    document.execCommand("insertText", false, "\u00A0".repeat(spacesToAdd));
  }
}

function doStandardTab(textArea: HTMLTextAreaElement) {
  document.execCommand("insertText", false, "\u00A0".repeat(4));
}

function doIndent(textArea: HTMLTextAreaElement) {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;
  const text = textArea.value;

  // Split the text into lines
  const lines = text.split("\n");

  // Calculate the line numbers that are in the selection
  const { startLine, endLine } = getSelectionLineNumbers(textArea);

  // Calculate the minimum x position of the first character of all of the lines within the selection
  let minX = Infinity;
  for (let i = startLine; i <= endLine; i++) {
    const firstCharX = lines[i].search(/\S|$/); // Find the first non-space character or end of line
    if (firstCharX < minX) {
      minX = firstCharX;
    }
  }

  // Calculate the difference to the nearest multiple of 4
  const spacesToAdd = 4 - (minX % 4);

  // Add that many spaces to the beginning of each line within the selection
  let linePosition = 0;
  let lineIndex = 0;
  for (const line of lines) {
    if (lineIndex >= startLine && lineIndex <= endLine) {
      textArea.selectionStart = linePosition;
      textArea.selectionEnd = linePosition;
      document.execCommand("insertText", false, "\u00A0".repeat(spacesToAdd));
      linePosition += spacesToAdd;
    }

    linePosition += line.length + 1;
    lineIndex++;
  }

  // Update the selection accordingly
  textArea.selectionStart = start + spacesToAdd;
  textArea.selectionEnd = end + spacesToAdd * (endLine - startLine + 1);
}

function doUnindent(textArea: HTMLTextAreaElement) {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;
  const text = textArea.value;

  // Split the text into lines
  const lines = text.split("\n");

  // Calculate the line numbers that are in the selection
  const { startLine, endLine } = getSelectionLineNumbers(textArea);

  // Calculate the minimum x position of the first character of all of the lines within the selection
  let minX = Infinity;
  for (let i = startLine; i <= endLine; i++) {
    const firstCharX = lines[i].search(/\S|$/); // Find the first non-space character or end of line
    if (firstCharX < minX) {
      minX = firstCharX;
    }
  }

  // Calculate the difference to the nearest lower multiple of 4
  const spacesToRemove = minX % 4 === 0 ? 4 : minX % 4;

  // Remove that many spaces from the beginning of each line within the selection
  let linePosition = 0;
  let lineIndex = 0;

  for (const line of lines) {
    if (lineIndex >= startLine && lineIndex <= endLine) {
      const firstCharX = lines[lineIndex].search(/\S|$/);

      console.log(firstCharX);

      const lineSpacesToRemove = Math.min(spacesToRemove, firstCharX);

      if (lineSpacesToRemove > 0) {
        textArea.selectionStart = linePosition;
        textArea.selectionEnd = linePosition + lineSpacesToRemove;
        document.execCommand("delete", false, undefined);
        linePosition -= lineSpacesToRemove;
      }
    }

    linePosition += line.length + 1;
    lineIndex++;
  }

  // Update the selection accordingly
  textArea.selectionStart = start - spacesToRemove;
  textArea.selectionEnd = end - spacesToRemove * (endLine - startLine + 1);
}

function getSelectionSize(textArea: HTMLTextAreaElement) {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;

  return end - start;
}

function getIfAnyCharactersToLeftOfSelection(textArea: HTMLTextAreaElement) {
  const start = textArea.selectionStart;
  const text = textArea.value;

  const lineStart = text.lastIndexOf("\n", start - 1) + 1; // Find the start of the line of the selection
  const lineUpToCursor = text.substring(lineStart, start); // Get the substring from the start of the line to the cursor
  return /\S/.test(lineUpToCursor); // Test if there are any non-space characters
}

function selectionIsBeginningOfLine(textArea: HTMLTextAreaElement) {
  const start = textArea.selectionStart;
  const text = textArea.value;

  return text.lastIndexOf("\n", start - 1) === start - 1;
}

function getSelectionLineNumbers(textArea: HTMLTextAreaElement) {
  const text = textArea.value;
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;

  let startLine = 0,
    endLine = 0,
    charCount = 0;
  const lines = text.split("\n");

  for (let i = 0; i < lines.length; i++) {
    if (charCount + lines[i].length >= start) {
      startLine = i;
      break;
    }
    charCount += lines[i].length + 1; // +1 for the newline character
  }

  charCount = 0;
  for (let i = 0; i < lines.length; i++) {
    if (charCount + lines[i].length >= end) {
      endLine = i;
      break;
    }
    charCount += lines[i].length + 1; // +1 for the newline character
  }

  return { startLine, endLine };
}

import React from "react";
import ReactDOM from "react-dom/client";
import "cropperjs/dist/cropper.css";
import "./index.css";

import { routeTree } from "./routeTree.gen";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import {
  CenteredSpinner,
  DelayedCenteredSpinner,
} from "./lib/interface/spinner";
import {
  ErrorScreen,
  NotFoundError,
  NotFoundScreen,
} from "./lib/meat/error-screen.tsx";

import { env } from "./env.ts";

import * as Sentry from "@sentry/react";

const router = createRouter({
  routeTree,
  notFoundMode: "fuzzy",
  defaultPendingMs: 100,
  defaultPendingMinMs: 0,
  defaultPendingComponent: () => <DelayedCenteredSpinner />,
  defaultErrorComponent: ({ error }) => {
    if (
      error.message.startsWith("Failed to fetch dynamically imported module:")
    ) {
      window.location.reload();
      return <CenteredSpinner />;
    }

    if (error instanceof NotFoundError) {
      return <NotFoundScreen to="/" />;
    }

    return <ErrorScreen heading="Error" desc={error.message} />;
  },
  defaultNotFoundComponent: () => <NotFoundScreen to="/" />,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

Sentry.init({
  dsn: "https://d6d521b9325a9f09f89490b630b2947c@o4505518432452608.ingest.us.sentry.io/4507946788847616",
  environment: env.sentryEnv,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", env.baseUrl],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

import { useFbValue } from "../foundation/p-node/firebase";

const defaultFeatureFlags = {
  image_fill_in_the_blank: false,
  input_device: false,
  markdown_fill_in_the_blank: false,
  my_worksheets: false,
  popup_feedback: false,
  unofficial_courses: false,
  unofficial_worksheet_deleting: false,
  user_linked_schools: false,
};

export type FbFeatureFlagsObject = typeof defaultFeatureFlags;

export function useFeatureFlags(): FbFeatureFlagsObject {
  const [featureFlags] =
    useFbValue<Partial<FbFeatureFlagsObject>>("/feature_flags");
  return {
    ...defaultFeatureFlags,
    ...featureFlags,
  };
}

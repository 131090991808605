import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_auth/schools/$schoolId/$department/$courseId/$worksheetId/",
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: "/schools/$schoolId/all/$courseId/$worksheetId",
      params,
    });
  },
});


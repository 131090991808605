import { useState } from "react";

export function useLocalStorage<T>(key: string, nullableFallback: T) {
  const value = getLocalStorageItem<T>(key);

  const [_, trigger] = useState({});

  return [
    value ?? nullableFallback,
    (value: T) => {
      setLocalStorageItem(key, value);
      trigger({});
    },
  ] as const;
}

export function setLocalStorageItem<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem<T>(key: string): T | undefined {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
}

import { ampl } from "@/lib/foundation/amplitude";
import { setLocalStorageItem } from "@/lib/foundation/local-storage";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/referral/$code/")({
  beforeLoad: ({ params }) => {
    const { code } = params;

    setLocalStorageItem("referralCode", code);

    ampl.track("Referral Link Clicked", {
      Code: code,
    });

    throw redirect({ to: "/" });
  },
});

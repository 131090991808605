import { getFbUserPromise } from "@/lib/foundation/firebase";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/uploads/my-uploads/")({
  beforeLoad: async () => {
    const user = await getFbUserPromise();
    if (!user) {
      throw redirect({ to: "/uploads" });
    }
  },
});

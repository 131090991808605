import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/upload/")({
  validateSearch: (search) => {
    return search as {
      school?: string;
      course?: string;
    };
  },
});

import Intercom, {
  boot,
  show as showIntercom,
  shutdown,
  update,
} from "@intercom/messenger-js-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useFbUser } from "../foundation/firebase";
import { getIntercomHmac } from "../meat/api";
import { IconButton } from "./button";
import LiveHelpIcon from "@/assets/live-help.svg?react";
import {
  InitType,
  IntercomSettings,
} from "@intercom/messenger-js-sdk/dist/types";
import { env } from "@/env";
import { DateTime } from "luxon";
import { ampl } from "../foundation/amplitude";

const openTime = {
  hour: 9,
  minute: 0,
};

const closeTime = {
  hour: 23,
  minute: 0,
};

export function IntercomProvider({ children }: { children: React.ReactNode }) {
  const user = useFbUser();
  const [intercomHmac, setIntercomHmac] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      getIntercomHmac().then(({ user_hash }) => setIntercomHmac(user_hash));
    }
  }, [user]);

  const initializedRef = useRef(false);

  useEffect(() => {
    if (user && intercomHmac) {
      const initArgs = {
        app_id: "bc2uouqo",
        hide_default_launcher: true,
        user_id: user.uid,
        user_hash: intercomHmac,
        name: user.displayName || undefined,
        email: user.email || undefined,
        created_at: Math.floor(
          user.metadata.creationTime
            ? new Date(user.metadata.creationTime).getTime() / 1000
            : Date.now() / 1000,
        ),
        "Live Support URL": `${window.origin}/live-support/${user.uid}/edit`,
      } satisfies InitType & IntercomSettings;

      if (!initializedRef.current) {
        Intercom(initArgs);
        initializedRef.current = true;
      } else {
        boot(initArgs);
      }
    }

    return () => {
      shutdown();
    };
  }, [user, intercomHmac]);

  return <>{children}</>;
}

export function IntercomButton({
  isTooltipOpen = false,
  onTooltipClose = () => {},
}: {
  isTooltipOpen?: boolean;
  onTooltipClose?: () => void;
}) {
  const [isOnline, setIsOnline] = useState<boolean>();
  const [nextOpenTime, setNextOpenTime] = useState<DateTime>();

  useEffect(() => {
    const checkOnlineStatus = () => {
      const now = DateTime.now().setZone(env.timeZone);

      const todayOpenTime = now.set({
        hour: openTime.hour,
        minute: openTime.minute,
        second: 0,
        millisecond: 0,
      });

      let todayCloseTime = todayOpenTime.set({
        hour: closeTime.hour,
        minute: closeTime.minute,
      });

      if (todayOpenTime > todayCloseTime) {
        todayCloseTime = todayCloseTime.plus({ days: 1 });
      }

      if (now >= todayOpenTime && now < todayCloseTime) {
        setIsOnline(true);
        setNextOpenTime(undefined);
      } else {
        setIsOnline(false);
        setNextOpenTime(todayOpenTime.plus({ days: 1 }));
      }
    };

    checkOnlineStatus();
    const timer = setInterval(checkOnlineStatus, 30000); // Check every 30 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative">
      {isOnline != null && (
        <IconButton
          onClick={() => {
            ampl.track("Support Button Clicked", { Location: "App Bar" });
            showIntercom();
          }}
        >
          <div className="relative flex items-center">
            <LiveHelpIcon className="w-6 h-6 fill-gray-700 hover:fill-black" />
            <div
              className={`w-2 h-2 ${isOnline ? "bg-green-500" : "bg-gray-500"} rounded-full mr-1 absolute top-0 -right-1 md:hidden`}
            ></div>
            <div className="ml-2 flex-col items-start hidden md:flex">
              <div className="flex items-center">
                <div
                  className={`w-2 h-2 ${isOnline ? "bg-green-500" : "bg-gray-500"} rounded-full mr-1`}
                ></div>
                <span
                  className={`text-sm ${isOnline ? "text-green-500" : "text-gray-500"}`}
                >
                  {isOnline ? "Online" : "Offline"}
                </span>
              </div>
              <span className="text-gray-500 text-xs">
                {isOnline
                  ? "Responds in a few minutes"
                  : nextOpenTime
                    ? `Responds at ${nextOpenTime.toLocal().toLocaleString(DateTime.TIME_SIMPLE, { locale: "en-US" })}`
                    : "Responds within 24 hours"}
              </span>
            </div>
          </div>
        </IconButton>
      )}

      {isTooltipOpen && (
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-white border-2 border-gray-300 rounded-md shadow-lg p-3 whitespace-nowrap z-50">
          <button
            onClick={onTooltipClose}
            className="absolute top-0 right-2 text-gray-500 hover:text-gray-700 text-lg"
          >
            ×
          </button>
          <p className="text-sm font-semibold text-gray-800">
            Issues with worksheet?
          </p>
          <p className="text-sm text-gray-600">Click here for live support!</p>
          <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white border-t border-l border-gray-300 rotate-45"></div>
        </div>
      )}
    </div>
  );
}

import { fbAuth, useFbUser } from "@/lib/foundation/firebase";
import { User, signOut } from "firebase/auth";
import { useCallback, useRef, useState } from "react";
import { getPlatform } from "../platform";
import { useChatQuota } from "../quota";
import { useSubscriptionActive } from "../payment";
import { Button, IconButton } from "@/lib/interface/button";
import Account from "@/assets/account.svg?react";
import { Link } from "@tanstack/react-router";
import { useShowDialog } from "@/lib/interface/dialog";
import { ContactUsDialog } from "./contact";
import {
  ContextMenu,
  ContextMenuChild,
} from "@/lib/interface/context-menu/components";

import LogoutIcon from "@/assets/logout.svg?react";
import { useFeatureFlags } from "../feature-flags";
import { IntercomButton } from "@/lib/interface/intercom";

export function AppBarProfile({
  isIntercomTooltipOpen = false,
  onIntercomTooltipClose = () => {},
}: {
  isIntercomTooltipOpen?: boolean;
  onIntercomTooltipClose?: () => void;
}) {
  const user = useFbUser();

  return user ? (
    <div className="flex items-center space-x-4">
      <IntercomButton
        isTooltipOpen={isIntercomTooltipOpen}
        onTooltipClose={onIntercomTooltipClose}
      />
      <LoggedInProfile user={user} />
    </div>
  ) : (
    getPlatform() !== "write" && getPlatform() !== "discuss" && (
      <Button AltComponent={Link} to="/login" outline>
        <span className="font-logo">Sign in</span>
      </Button>
    )
  );
}

function LoggedInProfile({ user }: { user: User }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const closeListenerRef = useRef<(innerEvent: MouseEvent) => void>();
  const onShowDropdown = useCallback(
    (e: React.MouseEvent) => {
      setDropdownVisible(!dropdownVisible);
      if (closeListenerRef.current) {
        window.removeEventListener("click", closeListenerRef.current);
      }
      closeListenerRef.current = (innerEvent: MouseEvent) => {
        if (innerEvent === e.nativeEvent) return;
        setDropdownVisible(false);
        if (closeListenerRef.current) {
          window.removeEventListener("click", closeListenerRef.current);
        }
      };
      window.addEventListener("click", closeListenerRef.current);
    },
    [dropdownVisible],
  );

  const platform = getPlatform();
  const showDialog = useShowDialog();

  const chatQuota = useChatQuota(user.uid);
  const subscriptionActive = useSubscriptionActive(user.uid);

  const featureFlags = useFeatureFlags();

  return (
    <div className="relative flex flex-row items-stretch h-full z-30">
      <div>
        <IconButton
          className="h-full"
          onClick={onShowDropdown}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onShowDropdown(e);
          }}
        >
          <div className="flex flex-row self-center items-stretch gap-[11px]">
            <Account className="w-[40px] h-[40px] fill-foreground opacity-40"></Account>
            <div className="flex-col hidden md:flex justify-center items-start ">
              {user.displayName && (
                <span className="font-heading">{user.displayName}</span>
              )}
              {user.email && <span className="text-[14px]">{user.email}</span>}
            </div>
          </div>
        </IconButton>
        {dropdownVisible && (
          <ContextMenu className="translate-y-1 translate-x-2 right-0 font-medium">
            {platform === "tutor" && (
              <>
                {!subscriptionActive && (
                  <div className="text-base py-2 px-4 text-left opacity-60 text-nowrap">
                    {`Trial Usage: ${chatQuota.sent}/${chatQuota.total} chats`}
                  </div>
                )}
                <Link to={"/my-requests"}>
                  <ContextMenuChild>Exam requests</ContextMenuChild>
                </Link>
                <Link to={"/subscribe"}>
                  <ContextMenuChild>
                    {subscriptionActive ? "Manage subscription" : "Subscribe"}
                  </ContextMenuChild>
                </Link>
                {featureFlags.user_linked_schools && (
                  <Link to="/enrollment">
                    <ContextMenuChild>Manage enrollment</ContextMenuChild>
                  </Link>
                )}
              </>
            )}
            {platform === "uploads" && (
              <>
                <Link
                  to={"/uploads/my-uploads"}
                  className="text-base font-medium py-3 px-5 text-foreground text-left hover:bg-foreground hover:bg-opacity-5 outline-primary text-nowrap"
                >
                  My Uploads
                </Link>
                <button
                  className="text-base font-medium py-3 px-5 text-foreground text-left hover:bg-foreground hover:bg-opacity-5 outline-primary text-nowrap"
                  onClick={() =>
                    showDialog((onClose) => (
                      <ContactUsDialog onClose={onClose} />
                    ))
                  }
                >
                  Contact us
                </button>
              </>
            )}
            <button
              className="text-error flex flex-row items-center"
              onClick={() => signOut(fbAuth)}
            >
              <ContextMenuChild icon={LogoutIcon} iconClassName={"fill-error"}>
                Log out
              </ContextMenuChild>
            </button>
          </ContextMenu>
        )}
      </div>
    </div>
  );
}

import { getClientQueryOptions, useClientQuery } from "../foundation/client";
import {
  getFbUserClaimsPromise,
  useFbUser,
  useFbUserClaims,
} from "../foundation/firebase";

export const roleAccessLevelMap = {
  ambassador: 50,
  school: 80,
  pilot: 100,
  worker: 200,
  admin: 300,
  pensieve: 400,
} as const;

export const accessLevelRoleMap = Object.fromEntries(
  Object.entries(roleAccessLevelMap).map(([key, value]) => [value, key])
);

export type Role = keyof typeof roleAccessLevelMap;

export function useIsRole(role: Role) {
  const claims = useFbUserClaims() as null | { access_level?: number };

  return !!(
    claims?.access_level && claims.access_level >= roleAccessLevelMap[role]
  );
}

export async function isRole(role: Role) {
  const claims = (await getFbUserClaimsPromise()) as null | {
    access_level?: number;
  };

  return !!(
    claims?.access_level && claims.access_level >= roleAccessLevelMap[role]
  );
}

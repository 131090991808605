import { env } from "@/env";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

if (typeof window !== "undefined") {
  amplitude.init(env.amplitudeApiKey, undefined, {
    serverUrl: "https://amplitude2.pensieve.co/2/httpapi",
  });
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });
  amplitude.add(sessionReplayTracking);
  amplitude.track("App Opened");
}

export const ampl = amplitude;

import { getFbUserPromise } from "@/lib/foundation/firebase";
import { NotFoundScreen } from "@/lib/meat/error-screen";
import { isRole } from "@/lib/meat/roles";
import {
  getSchoolFromEmail,
  getSchools,
} from "@/lib/meat/schools";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/schools/$schoolId")({
  notFoundComponent: () => <NotFoundScreen to="/schools" />,
  beforeLoad: async ({ context, params }) => {
    const [user, schools, isPilot] = await Promise.all([
      getFbUserPromise(),
      getSchools(context.queryClient),
      isRole("pilot"),
    ]);

    const school = getSchoolFromEmail(user, schools);

    if (!isPilot) {
      if (school != null && school.id !== params.schoolId) {
        throw redirect({
          to: "/schools/$schoolId",
          params: { schoolId: school.id },
        });
      }
    }
  },
  component: () => <Wrapper />,
});

function Wrapper() {
  return <Outlet />;
}

import cn from "classnames";
import Logo from "@/assets/logo.svg?react";
import {
  Link,
  useMatch,
  useMatchRoute,
  useMatches,
  useRouteContext,
} from "@tanstack/react-router";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Suspense,
  useDeferredValue,
  useEffect,
} from "react";
import { AppBarProfile } from "./profile";
import { getPlatform } from "../platform";
import { AppBarSpacer } from "./actions";

export function AppBar({ className }: { className?: string }) {
  const lastMatch = useMatches().at(-1);
  const customAppBar = lastMatch?.context?.customAppBar ?? false;

  const pathname = window.location.pathname;
  const customContent = useContext(AppBarCustomContentContext);
  const setCustomContent = useAppBarCustomContentSetter();
  const deferredCustomContent = useDeferredValue(customContent);

  useEffect(() => {
    if (!customAppBar) {
      setCustomContent(null);
    }
  }, [customAppBar, setCustomContent]);

  return (
    <nav
      className={cn(
        "flex flex-row items-center h-[70px] px-[30px] border-b-border border-b-[1px] bg-white z-30",
        pathname === "/" && "border-b-0",
        className,
      )}
    >
      <Suspense fallback={<DefaultAppBarContent />}>
        {deferredCustomContent ?? <DefaultAppBarContent />}
      </Suspense>
    </nav>
  );
}

export type AppBarLogoProps = {
  to?: string;
};

export function AppBarLogo({ to }: AppBarLogoProps) {
  if (getPlatform() === "write") {
    return <Logo height={45} />;
  }

  return (
    <Link to={to || "/"}>
      <Logo height={45} />
    </Link>
  );
}

export function DefaultAppBarContent() {
  return (
    <>
      <AppBarLogo />
      <AppBarSpacer />
      <AppBarProfile />
    </>
  );
}

const AppBarCustomContentSetterContext = createContext<
  ((content: ReactNode | null | undefined) => void) | undefined
>(undefined);

const AppBarCustomContentContext = createContext<ReactNode | null | undefined>(
  null,
);

export const AppBarCustomContentProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [customContent, setCustomContent] = useState<
    ReactNode | null | undefined
  >(null);

  return (
    <AppBarCustomContentContext.Provider value={customContent}>
      <AppBarCustomContentSetterContext.Provider value={setCustomContent}>
        {children}
      </AppBarCustomContentSetterContext.Provider>
    </AppBarCustomContentContext.Provider>
  );
};

export function useAppBarCustomContentSetter() {
  const context = useContext(AppBarCustomContentSetterContext);
  if (!context) {
    throw new Error(
      "useAppBarCustomContentSetter must be used within a AppBarCustomContentSetterProvider",
    );
  }
  return context;
}

export function useSetAppBarCustomContent(
  content: ReactNode | null | undefined,
) {
  const setCustomContent = useAppBarCustomContentSetter();

  useEffect(() => {
    setCustomContent(content);
  }, [setCustomContent, content]);
}

import { getFbUserPromise } from "@/lib/foundation/firebase";
import { createFileRoute, redirect } from "@tanstack/react-router";
import * as nativeApi from "@/lib/meat/native-api";

export const Route = createFileRoute("/write/active-write/")({
  beforeLoad: async () => {
    if (!(await getFbUserPromise())) {
      nativeApi.setCanvasVisible(false);
      throw redirect({ to: "/write" });
    }

    return {
      customAppBar: true,
    };
  },
});

import { getFbUserPromise } from "@/lib/foundation/firebase";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/write/")({
  beforeLoad: async () => {
    if (await getFbUserPromise()) {
      throw redirect({ to: "/write/active-write" });
    }
  },
});

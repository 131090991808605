import { useClientQuery } from "../foundation/client";
import { useFbValue } from "../foundation/p-node/firebase";
import { DateTime } from "luxon";
import { env } from "@/env";

export function useUserPayment(uid: string) {
  return useFbValue<UserPayment>(`/payment/${uid}`);
}

export function useUserPaymentPlan(uid: string) {
  return useFbValue<PaymentPlanPeriod[] | boolean | undefined>(
    `/payment/${uid}/payment_plan`
  );
}

export function useSubscribeLink() {
  const { data } = useClientQuery<string>(`/payment/monthly_subscribe_link`, {
    refetchInterval: 600 * 1000,
  });
  return data;
}

export function useCustomerPortalLink() {
  const { data } = useClientQuery<string>(`/payment/customer_portal_link`, {
    refetchInterval: 600 * 1000,
  });
  return data;
}

export function getPeriodFor(
  paymentPlan: PaymentPlanPeriod[],
  time: number
): PaymentPlanPeriod | null {
  const now = DateTime.fromMillis(time, { zone: env.timeZone });

  const period = paymentPlan.find((period) => {
    const start = DateTime.fromObject(period.start, { zone: env.timeZone });
    let end = DateTime.fromObject(period.end, { zone: env.timeZone }).plus({
      days: 1,
    });

    if (end < start) {
      end = end.plus({ years: 1 });
    }

    return now >= start && now < end;
  });

  return period || null;
}

export function getNextPeriodFor(
  paymentPlan: PaymentPlanPeriod[],
  time: number
): PaymentPlanPeriod | null {
  const now = DateTime.fromMillis(time, { zone: env.timeZone });

  const sortedPaymentPlan = Array.from(paymentPlan).sort((a, b) => {
    let aStart = DateTime.fromObject(a.start, { zone: env.timeZone });
    let bStart = DateTime.fromObject(b.start, { zone: env.timeZone });

    if (aStart < now) {
      aStart = aStart.plus({ years: 1 });
    }

    if (bStart < now) {
      bStart = bStart.plus({ years: 1 });
    }

    return aStart.toMillis() - bStart.toMillis();
  });

  return sortedPaymentPlan[0] || null;
}

export function getTimeFor(day: Day, end?: boolean) {
  let date = DateTime.fromObject(day, { zone: env.timeZone });
  if (end) {
    date = date.plus({ days: 1 });
  }
  return date.toMillis();
}

export function getNextTimeFor(
  dayInYear: DayInYear,
  time: number,
  end?: boolean
) {
  const now = DateTime.fromMillis(time, { zone: env.timeZone });

  let nextDay = DateTime.fromObject(dayInYear, { zone: env.timeZone });

  if (end) {
    nextDay = nextDay.plus({ days: 1 });
  }

  if (nextDay < now) {
    nextDay = nextDay.plus({ years: 1 });
  }

  return nextDay.toMillis();
}

export function useSubscriptionActive(uid: string) {
  const [userPayment] = useUserPayment(uid);

  if (!userPayment?.paid_through) {
    return false;
  }

  const paidThroughDate = DateTime.fromObject(userPayment.paid_through, {
    zone: env.timeZone,
  }).plus({ days: 1 });

  const now = DateTime.local({ zone: env.timeZone });

  if (now < paidThroughDate) {
    return true;
  }

  return false;
}

export type PaymentSystem = {
  intro_price: number;
  summer_price: number;
  payment_plan: PaymentPlanPeriod[];
};

export type UserPayment = {
  payment_plan?: PaymentPlanPeriod[] | null | boolean;
  paid_through?: Day | null;
  payment_price_per_month?: number | null;
};

type Day = {
  year: number;
  month: number;
  day: number;
};

type DayInYear = {
  month: number;
  day: number;
};

type PaymentPlanPeriod = {
  start: DayInYear;
  end: DayInYear;
  price: number;
};

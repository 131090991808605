/** @type {import('tailwindcss').Config} */
export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    extend: {
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic":
          "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
      },
      fontFamily: {
        sans: ['"Noto Sans"', "sans-serif"],
        mono: ['"Noto Sans Mono"', "monospace"],
        logo: ["Inter", "sans-serif"],
        heading: ['"Lexend Deca"', "sans-serif"],
      },
      colors: {
        primary: "#008080",
        complementary: "#0386FF",
        secondary: "#005858",
        tertiary: "#CA6100",
        success: "#23A660",
        error: "#E23E3E",
        warning: "#FFCC00",
        background: "#F5F6FA",
        lightBackground: "#E6F3F3",
        foreground: "#18212E",
        border: "#E4E7F2",
      },
    },
  },
  plugins: [],
};

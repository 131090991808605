import { Router, ToOptions, useNavigate } from "@tanstack/react-router";
import { Button } from "../interface/button";
import { routeTree } from "@/routeTree.gen";

export function ErrorScreen({
  heading,
  desc,
  buttonLabel = "Retry",
  onButtonPressed = () => {
    window.location.reload();
  },
}: {
  heading: string;
  desc: string;
  buttonLabel?: string;
  onButtonPressed?: () => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full gap-2">
      <h1 className="text-2xl font-bold font-heading text-center">{heading}</h1>
      <p className="text-sm text-gray-500 max-w-[300px] text-center mb-4">
        {desc}
      </p>
      <Button outline onClick={onButtonPressed}>
        {buttonLabel}
      </Button>
    </div>
  );
}

export function NotFoundScreen(toOptions: ToOptions) {
  const navigate = useNavigate();

  return (
    <ErrorScreen
      heading="Not Found"
      desc="The page you are looking for does not exist."
      buttonLabel="Go Back"
      onButtonPressed={() => navigate(toOptions)}
    />
  );
}

export function UnauthorizedScreen() {
  const navigate = useNavigate();

  return (
    <ErrorScreen
      heading="Unauthorized"
      desc="You are not authorized to access this page."
      buttonLabel="Go back"
      onButtonPressed={() => {
        navigate({ to: "/" });
      }}
    />
  );
}

export class NotFoundError extends Error {
  constructor() {
    super("Not Found");
  }
}

export class UserFriendlyError extends Error {
  constructor(message: string) {
    super(message);
  }
}

import { useEffect, useRef } from "react";

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        pensieveWriteMessageHandler?: {
          postMessage: (message: any) => void;
        };
      };
    };
  }
}

export function postNativeMessage<T>(message: T) {
  window?.webkit?.messageHandlers?.pensieveWriteMessageHandler?.postMessage(
    message
  );
}

export function onNativeMessage<T>(callback: (message: T) => void): () => void {
  const listener = (e: Event) => callback((e as CustomEvent).detail);

  window.addEventListener("pensieveWriteMessage", listener);
  return () => window.removeEventListener("pensieveWriteMessage", listener);
}

export function useOnNativeMessage<T>(callback: (message: T) => void) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useEffect(() => {
    const listener = (e: Event) =>
      callbackRef.current((e as CustomEvent).detail);

    window.addEventListener("pensieveWriteMessage", listener);
    return () => window.removeEventListener("pensieveWriteMessage", listener);
  }, []);
}

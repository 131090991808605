import { ampl } from "@/lib/foundation/amplitude";
import { tiktokTrack } from "@/lib/meat/api";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/dashboard/")({
  beforeLoad: () => {
    tiktokTrack({
      event: "ViewContent",
      content_id: "dashboard",
      ttclid: sessionStorage.getItem("ttclid") ?? undefined,
    }).catch((e) => {
      console.error(e);
    });

    tiktokTrack({
      event: "View Dashboard",
      ttclid: sessionStorage.getItem("ttclid") ?? undefined,
    }).catch((e) => {
      console.error(e);
    });

    ampl.track("Dashboard Opened");
  },
});

import { AlertDialog } from "@/lib/interface/dialog";

export function ContactUsDialog({ onClose }: { onClose: () => void }) {
  return (
    <AlertDialog
      className="max-w-[400px]"
      title="Contact Us"
      message={
        <>
          Please email <b>hello@pensieve.co</b> if you have any questions,
          feedback, suggestions, or bug reports. We will try our best to answer
          all inquiries within <b>24 hours</b>.
        </>
      }
      onClose={onClose}
    />
  );
}

import { useMediaQuery } from "react-responsive";
import resolveConfig from "tailwindcss/resolveConfig";
import theme from "../../../tailwind.config"; // Your tailwind config
import { Listenable } from "./listenable";
import { useEffect, useRef } from "react";

const breakpoints = resolveConfig(theme).theme.screens;

type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  return useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });
}

export function useListenableFn(listenable: Listenable, fn: () => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    return listenable.subscribe(() => fnRef.current());
  }, [listenable]);
}

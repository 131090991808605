import { redirect } from "@tanstack/react-router";

export function getPlatform() {
  const path = window.location.pathname;

  if (path.startsWith("/write")) {
    return "write";
  } else if (path.startsWith("/discuss")) {
    return "discuss";
  } else if (path.startsWith("/uploads")) {
    return "uploads";
  } else {
    return "tutor";
  }
}

export function redirectToPlatform() {
  if (window.location.pathname.startsWith("/login")) {
    return;
  }

  const platform = getPlatform();
  const hostname = window.location.hostname;

  if (
    hostname.startsWith("tutor.") &&
    platform !== "tutor" &&
    platform !== "write"
  ) {
    throw redirect({ to: "/" });
  } else if (hostname.startsWith("uploads.") && platform !== "uploads") {
    throw redirect({ to: "/uploads" });
  } else if (hostname.startsWith("write.") && platform !== "write") {
    throw redirect({ to: "/write" });
  } else if (hostname.startsWith("discuss.") && platform !== "discuss") {
    throw redirect({ to: "/discuss" });
  }
}

export type Platform = "write" | "tutor" | "discuss" | "uploads";

import { ampl } from "@/lib/foundation/amplitude";
import { getFbUserPromise, useFbUser } from "@/lib/foundation/firebase";
import { getLocalStorageItem } from "@/lib/foundation/local-storage";
import {
  AlertDialog,
  useShowDialog,
  useTryPopup,
} from "@/lib/interface/dialog";
import { claimReferral } from "@/lib/meat/referral";
import {
  Navigate,
  Outlet,
  createFileRoute,
  redirect,
  useLocation,
} from "@tanstack/react-router";
import { useEffect, useRef } from "react";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location }) => {
    if ((await getFbUserPromise()) == null) {
      throw redirect({ to: "/login", search: { redirect: location.pathname } });
    }
  },
  component: () => <Wrapper />,
});

function Wrapper() {
  const user = useFbUser();
  const location = useLocation();

  const tryPopup = useTryPopup();
  const showDialog = useShowDialog();

  const startedClaiming = useRef(false);

  useEffect(() => {
    if (startedClaiming.current) {
      return;
    }

    startedClaiming.current = true;

    const referralCode = getLocalStorageItem<string>("referralCode");

    if (referralCode != null) {
      tryPopup(async () => {
        try {
          await claimReferral(referralCode);
          ampl.track("Referral Claimed", {
            code: referralCode,
          });
          showDialog((onClose) => (
            <AlertDialog
              className="min-w-[400px]"
              onClose={onClose}
              title="Referral Claimed"
              message="To complete your referral, please go to a worksheet and send a chat."
            />
          ));
        } catch (e) {
          ampl.track("Referral Error Occurred", {
            code: referralCode,
            error: String(e),
          });
          throw e;
        } finally {
          localStorage.removeItem("referralCode");
        }
      });
    }
  }, [tryPopup, showDialog]);

  if (user == null) {
    return <Navigate to="/login" search={{ redirect: location.pathname }} />;
  }

  return <Outlet />;
}

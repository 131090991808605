import { User } from "firebase/auth";
import { env } from "@/env";
import axios, { AxiosError } from "axios";
import { getFbUser } from "./firebase";
import {
  QueryClient,
  UseSuspenseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { UserFriendlyError } from "../meat/error-screen";

class Client {
  constructor(
    private readonly user?: User | null,
    private readonly baseUrl: string | null = null,
  ) {}

  async get(path: string, query?: Record<string, any> | null) {
    return this.request("get", path, { query });
  }

  async post(path: string, body?: any) {
    return this.request("post", path, { body });
  }

  async postForm(path: string, body?: any) {
    return this.request("postForm", path, { body });
  }

  async put(path: string, body?: any) {
    return this.request("put", path, { body });
  }

  private async request(
    method: "get" | "post" | "put" | "postForm",
    path: string,
    { body, query }: { body?: any; query?: Record<string, any> | null },
  ) {
    try {
      let response;
      if (method === "get") {
        response = await axios.get(`${this.baseUrl || env.baseUrl}${path}`, {
          headers: await this.getHeaders(),
          params: query,
        });
      } else if (method === "post") {
        response = await axios.post(
          `${this.baseUrl || env.baseUrl}${path}`,
          body,
          {
            headers: await this.getHeaders(),
          },
        );
      } else if (method === "put") {
        response = await axios.put(
          `${this.baseUrl || env.baseUrl}${path}`,
          body,
          {
            headers: await this.getHeaders(),
          },
        );
      } else if (method === "postForm") {
        response = await axios.postForm(
          `${this.baseUrl || env.baseUrl}${path}`,
          body,
          {
            headers: {
              ...(await this.getHeaders()),
              "Content-Type": "multipart/form-data; charset=UTF-8",
            },
          },
        );
      } else {
        throw new Error("Invalid method");
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          if (
            error.response.status &&
            error.response.status >= 400 &&
            error.response.status < 500 &&
            error.response.data.detail
          ) {
            throw new UserFriendlyError(error.response.data.detail);
          }
        } else if (error.request) {
          throw error;
        }
        throw error;
      }
      throw error;
    }
  }

  private async getHeaders() {
    const token = await this.user?.getIdToken();
    return {
      "Content-Type": "application/json; charset=UTF-8",
      "X-API-Version": "2",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  }
}

export function getClient(baseUrl: string | null = null) {
  return new Client(getFbUser().user, baseUrl);
}

export function useClientQuery<T>(
  path: string | null,
  config?: Partial<UseSuspenseQueryOptions<T>>,
) {
  return useSuspenseQuery<T>({ ...getClientQueryOptions<T>(path), ...config });
}

export function getClientQueryOptions<T>(path: string | null) {
  return {
    queryKey: [path],
    queryFn: async () => (path ? await getClient().get(path) : null) as T,
  };
}

import { Outlet, createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/write")({
  component: () => <Wrapper />,
});

function Wrapper() {
  useEffect(() => {
    const zoomPreventer = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    window.addEventListener("touchmove", zoomPreventer);
    return () => {
      window.removeEventListener("touchmove", zoomPreventer);
    };
  }, []);

  return <Outlet />;
}

import { getClient, useClientQuery } from "../foundation/client";
import { useFbValue, useOnceForever } from "../foundation/p-node/firebase";
import { AlertDialog, useShowDialog } from "../interface/dialog";

export function useReferralLink() {
  const { data } = useClientQuery<string>("/referral/code", {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (data == null) return null;

  return `${window.location.origin}/referral/${data}`;
}

export async function claimReferral(code: string) {
  await getClient().post("/referral/code/claim", { code });
}

export async function redeemTremendous(option: string, points: number) {
  return (await getClient().post("/referral/tremendous/redeem", {
    option,
    points,
  })) as number;
}

export function useReferral(uid: string) {
  const [referrerId] = useFbValue<string>(
    `/referral_system/referred_by/${uid}`
  );

  const [referral] = useFbValue<Referral | null>(
    `/referral_system/referrals/${referrerId}/${uid}`
  );

  return referral;
}

export function usePoints(uid: string) {
  const [points] = useFbValue<number | null>(`/points/${uid}`);

  return points ?? 0;
}

export function ReferralCompleteNotifier({ uid }: { uid: string }) {
  const referral = useReferral(uid);

  const showDialog = useShowDialog();

  useOnceForever(uid, referral?.complete ? "referral-complete" : null, () => {
    if (referral?.complete) {
      showDialog((onClose) => {
        return (
          <AlertDialog
            onClose={onClose}
            title="Referral Complete"
            message="Thank you for completing your referral!"
          />
        );
      });
    }
  });

  return null;
}

export type Referral = {
  created: number;
  complete: boolean;
};

import { useFbValue } from "../foundation/p-node/firebase";

const defaultChatQuota = 40;

export function useChatQuota(uid: string) {
  const [fbValue] = useFbValue<Partial<ChatQuotaInfo>>(`/chat_quotas/${uid}`);

  return {
    sent: fbValue?.sent ?? 0,
    total: fbValue?.total ?? defaultChatQuota,
  };
}

export type ChatQuotaInfo = {
  sent: number;
  total: number;
};

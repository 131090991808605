import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_auth/schools/$schoolId/all/$courseId/$worksheetId/",
)({
  beforeLoad: () => {
    return {
      customAppBar: true,
    };
  },
});
